export default {
  initialState: {
    isMobile: true,
    showHeaderBg: false,
    showSearchModal: false,
    notification: null
  },
  actions: [
    {
      name: 'setIsMobile',
      cb: (_, value) => {
        return { isMobile: value }
      }
    },
    {
      name: 'setShowSearchModal',
      cb: (_, value) => {
        return { showSearchModal: value }
      }
    },
    {
      name: 'setNotification',
      cb: (_, value) => {
        return { notification: value }
      }
    }
  ]
}

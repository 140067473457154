import styled from 'styled-components'

export const OrganizationsWrapper = styled.div`
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  max-width: 100%;
  width: 500px;
  padding: 24px;
  border-radius: 10px;

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const Info = styled.div`
  padding: 8px 0px;
`

export const Buttons = styled.div`
  display: flex;
  width: 500px;
  max-width: 100%;
  justify-content: space-between;
  margin-top: 16px;

  > button {
    width: 49%;
  }
`

export const InfoModal = styled.div`
  width: 500px;
  max-width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;

  > h1 {
    width: 100%;
    text-align: center;
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

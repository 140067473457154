import React from 'react'
import GqlDetails from 'components/GqlDetails'
import { GET_ORGANIZATION, UPDATE_ORGANIZATION } from 'gql'
import RecentActorsIcon from '@material-ui/icons/RecentActors'

const Organizations = () => {
  return (
    <GqlDetails
      config={{
        icon: <RecentActorsIcon />,
        gqlGet: GET_ORGANIZATION,
        gqlPut: UPDATE_ORGANIZATION,
        dataName: 'organization',
        formRows: [
          [
            { label: 'Name', id: 'name', adminOnly: true, type: 'input' },
            { label: 'Slug', id: 'slug', adminOnly: true, type: 'input' },
            { label: 'Tagline', id: 'tagline', type: 'input' }
          ],
          [
            { label: 'About', id: 'about', type: 'input', multiline: true }
          ],
          [
            { label: 'Website', id: 'website', type: 'input' },
            { label: 'Public Email', id: 'email', type: 'input' },
            { label: 'Public Phone Number', id: 'phone', type: 'input' }
          ],
          [
            { label: 'Facebook', id: 'facebook', type: 'input' },
            { label: 'Twitter', id: 'twitter', type: 'input' },
            { label: 'YouTube', id: 'youtube', type: 'input' },
            { label: 'Instagram', id: 'instagram', type: 'input' }
          ],
          [
            { label: 'Box Office Hours', id: 'boxOfficeHours', type: 'input', multiline: true }
          ]
        ]
      }}
    />
  )
}

export default Organizations

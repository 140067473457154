import React from 'react'
import GqlDetails from 'components/GqlDetails'
import { GET_EPISODE, UPDATE_ORGANIZATION } from 'gql'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'

const Organizations = () => {
  return (
    <GqlDetails
      config={{
        icon: <VideoLibraryIcon />,
        gqlGet: GET_EPISODE,
        gqlPut: UPDATE_ORGANIZATION,
        dataName: 'episode',
        formRows: [
          [
            { label: 'Name', id: 'name', adminOnly: true, type: 'input' },
            { label: 'Slug', id: 'slug', adminOnly: true, type: 'input' },
            { label: 'Url', id: 'url', adminOnly: true, type: 'input' }
          ],
          [
            {
              label: 'Description',
              id: 'description',
              adminOnly: true,
              type: 'input',
              multiline: true
            }
          ]
        ]
      }}
    />
  )
}

export default Organizations

import React from 'react'
import { PaginationWrapper } from './styled'
import { Pagination as SUIPagination } from 'semantic-ui-react'

const Pagination = props => {
  const { page, count, onChange } = props

  if (!count) {
    return null
  }

  return (
    <PaginationWrapper>
      <SUIPagination
        onPageChange={(e, { activePage }) => onChange(activePage)}
        activePage={page}
        totalPages={Math.ceil(count / 10)}
      />
    </PaginationWrapper>
  )
}

export default Pagination

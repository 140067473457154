import React from 'react'
import GqlTable from 'components/GqlTable'
import { GET_EVENTS } from 'gql'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber'
import { EventsWrapper } from './styled'
import Button from 'components/CustomButtons/Button.js'

const Organizations = () => {
  const history = useHistory()

  return (
    <EventsWrapper>
      <GqlTable
        config={{
          icon: <ConfirmationNumber />,
          title: 'Events',
          columns: ['name', 'startDate', 'endDate'],
          labels: ['Name', 'First Day', 'Last Day'],
          gql: GET_EVENTS,
          to: data => `/admin/events/${data.id}`,
          toText: 'View Event',
          customValue: {
            createdAt: v => moment(new Date(v)).format('LLL')
          }
        }}
        history={history}
      />
      <Button
        color='rose'
        fullWidth
        style={{ marginTop: 32 }}
        to='/admin/new-event'
      >
        Create New Event
      </Button>
    </EventsWrapper>
  )
}

export default Organizations

import React, { useEffect, useState } from 'react'
import { InfoModal, Info } from './styled'
import { GET_ORGANIZATION_APPLICATION } from 'gql'
import { useLazyQuery } from '@apollo/client'
import { useStoreon } from 'storeon/react'
import { useParams, useHistory } from 'react-router-dom'
import slugify from 'slugify'
import { approveOrganization } from 'api'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CardBody from 'components/Card/CardBody.js'
import Card from 'components/Card/Card.js'
import moment from 'moment'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Dialog from '@material-ui/core/Dialog'

const OrganizationsApplication = () => {
  const { user } = useStoreon('user')
  const [getData, { loading, data }] = useLazyQuery(GET_ORGANIZATION_APPLICATION)
  const { id } = useParams()
  const history = useHistory()
  const [rejectReason, setRejectReason] = useState('')
  const [showReject, setShowReject] = useState(false)
  const [showApprove, setShowApprove] = useState(false)
  const [slugError, setSlugError] = useState(false)
  const [newOrg, setNewOrg] = useState({
    name: '',
    slug: '',
    owner: ''
  })

  const handleNewOrgChange = (newState) => {
    setSlugError(false)

    setNewOrg(prev => ({
      ...prev,
      ...newState
    }))
  }

  useEffect(() => {
    (async () => {
      if (user) {
        const token = await user.getIdToken()

        getData({
          variables: { id },
          context: {
            headers: {
              authorization: `Bearer ${token}`,
              'X-Hasura-Role': 'rca'
            }
          }
        })
      }
    })()
  }, [getData, user, id])

  useEffect(() => {
    if (data) {
      const [{ orgName, user }] = data.data

      setNewOrg({
        name: orgName,
        slug: slugify(orgName, {
          lower: true,
          strict: true
        }),
        owner: user.email
      })
    }
  }, [data])

  const handleApprove = async () => {
    const [{ uid, id }] = data.data

    const token = await user.getIdToken()

    const { error, organizationId } = await approveOrganization({
      name: newOrg.name,
      slug: newOrg.slug,
      email: newOrg.owner,
      uid,
      id,
      token
    })

    if (error === 'slug') {
      setSlugError(true)
    } else {
      history.push(`/admin/organizations/${organizationId}`)
    }
  }

  const renderValue = (value, label, size) => {
    return (
      <GridItem xs={12} sm={12} md={size}>
        <Info>
          <label>{label}</label>
          <div>{value}</div>
        </Info>
      </GridItem>
    )
  }

  if (!data || loading) {
    return null
  }

  const [{ contactEmail, contactName, createdAt, description, orgName }] = data.data
  const { email } = user

  return (
    <>
      <GridContainer justify='center'>
        <Card>
          <CardBody>
            <GridContainer justify='center'>
              {renderValue(orgName, 'Organization Name', 6)}
              {renderValue(moment(new Date(createdAt)).format('LLL'), 'Submitted At', 6)}
            </GridContainer>
            <GridContainer justify='center'>
              {renderValue(contactName, 'Contact Name', 4)}
              {renderValue(contactEmail, 'Contact Email', 4)}
              {renderValue(email, 'Created by', 4)}
            </GridContainer>
            <GridContainer justify='center'>
              {renderValue(description, 'Website & Description', 12)}
            </GridContainer>
            <GridContainer justify='flex-end'>
              <GridItem>
                <Button color='danger' onClick={() => setShowReject(true)}>Reject</Button>
              </GridItem>
              <GridItem>
                <Button color='success' onClick={() => setShowApprove(true)}>Approve</Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <Dialog open={showReject} onClose={() => setShowReject(false)}>
        <InfoModal>
          <h1>Reject Application</h1>
          <CustomInput
            labelText='Rejection Reason'
            inputProps={{
              value: rejectReason,
              onChange: e => setRejectReason(e.target.value),
              placeholder: 'Why are you rejecting this?',
              multiline: true
            }}
          />
          <Button disabled={!rejectReason} color='danger'>Reject</Button>
        </InfoModal>
      </Dialog>
      <Dialog open={showApprove} onClose={() => setShowApprove(false)}>
        <InfoModal>
          <h1>Approve Application</h1>
          <Info>
            <label>Organization Account Owner</label>
            <div>{newOrg.owner}</div>
          </Info>
          <CustomInput
            labelText='Organization Name'
            inputProps={{
              value: newOrg.name,
              onChange: e => handleNewOrgChange({ name: e.target.value })
            }}
          />
          <CustomInput
            labelText='Organization Slug'
            error={slugError && 'Slug already exists!'}
            helperText={slugError && 'Slug already exists!'}
            inputProps={{
              value: newOrg.slug,
              onChange: e => handleNewOrgChange({ slug: e.target.value })
            }}
          />
          <Button color='success' onClick={handleApprove} disabled={!newOrg.name || !newOrg.slug}>Approve</Button>
        </InfoModal>
      </Dialog>
    </>
  )
}

export default OrganizationsApplication

import React from 'react'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
}

class Step1 extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      tagline: '',
      description: '',
      taglineState: '',
      descriptionState: ''
    }
  }

  sendState () {
    return this.state
  }

  change (event, stateName) {
    this.setState({ [`${stateName}State`]: '' })
    this.setState({ [stateName]: event.target.value })
  }

  isValidated () {
    const { tagline, description } = this.state

    if (tagline && description) {
      return true
    } else {
      if (!tagline) {
        this.setState({ taglineState: 'error' })
      }
      if (!description) {
        this.setState({ descriptionState: 'error' })
      }
    }
    return false
  }

  render () {
    const { classes } = this.props

    return (
      <GridContainer justify='center' style={{ maxWidth: 600 }}>
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Give us a quick tagline that describes your organization.
          </h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={11}>
          <CustomInput
            success={this.state.taglineState === 'success'}
            error={this.state.taglineState === 'error'}
            labelText={
              <span>
                Tagline
              </span>
            }
            id='tagline'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, 'tagline', 'tagline'),
              maxLength: 60
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} style={{ margin: '16px 0px' }}>
          <h4 className={classes.infoText}>
            Now give us a full description. Feel free to use as many characters as you want.
          </h4>
        </GridItem>
        <GridItem xs={12} sm={12} lg={11}>
          <CustomInput
            success={this.state.descriptionState === 'success'}
            error={this.state.descriptionState === 'error'}
            labelText={
              <span>
                Description
              </span>
            }
            id='email'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, 'description', 'description'),
              multiline: true
            }}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

Step1.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(Step1)

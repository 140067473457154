import React, { useState } from 'react'

// core components
import Wizard from 'components/Wizard/Wizard.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useStoreon } from 'storeon/react'
import SweetAlert from 'react-bootstrap-sweetalert'
import CircularProgress from '@material-ui/core/CircularProgress'

import Step1 from './steps/Step1.js'
import Step2 from './steps/Step2.js'
import Step3 from './steps/Step3.js'

import { uploadOrganizationPhotos } from 'api'
import { UPDATE_ORGANIZATION } from 'gql'
import { useMutation } from '@apollo/client'
import { LoadingWrapper } from './styled'

export default function WizardView () {
  const [loading, setLoading] = useState(false)
  const { user, organizations } = useStoreon('user', 'organizations')
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const handleSubmit = async data => {
    setLoading(true)

    const token = await user.getIdToken()

    const { about, helpfulInfo, photos } = data

    await uploadOrganizationPhotos({
      files: photos.files,
      token,
      organizationId: organizations[0]
    })

    await updateOrganization(({
      variables: {
        ...about,
        ...helpfulInfo,
        id: organizations[0]
      },
      context: {
        headers: {
          authorization: `Bearer ${token}`,
          'X-Hasura-Role': 'organization'
        }
      }
    }))

    setLoading(false)
    window.location.reload()
  }

  return (
    <GridContainer justify='center'>
      <GridItem>
        <Wizard
          validate
          steps={[
            { stepName: 'About', stepComponent: Step1, stepId: 'about' },
            { stepName: 'Helpful Info', stepComponent: Step2, stepId: 'helpfulInfo' },
            { stepName: 'Photos', stepComponent: Step3, stepId: 'photos' }
          ]}
          title='Almost there!'
          subtitle='We just need a few pieces of information to take you live.'
          finishButtonClick={handleSubmit}
        />
      </GridItem>
      {
        loading && (
          <SweetAlert
            title=''
            style={{ display: 'block', marginTop: '-100px' }}
            onConfirm={() => {}}
            onCancel={() => {}}
            customButtons={[]}
          >
            <LoadingWrapper>
              <div>Please wait as we update your profile.</div>
              <CircularProgress />
            </LoadingWrapper>
          </SweetAlert>
        )
      }
    </GridContainer>
  )
}

import { gql } from '@apollo/client'

export default gql`
  query GET_HOME {
    episodes(limit: 5, order_by: {created_at: desc}) {
      id
      imagePath: image_path
      name
      url
    }
    articles(limit: 3, order_by: {date: desc}) {
      title
      slug
      imagePath: image_path
    }
  }
`

import { gql } from '@apollo/client'

export default gql`
  query GET_EVENT($id: Int!) {
    event: events_by_pk(id: $id) {
      id
      about
      artists
      created_at
      isDigital: is_digital
      location
      maxPrice: max_price
      minPrice: min_price
      startDate: start_date
      endDate: end_date
      netflixShows: netflix_shows
      status
      name
      scene
      slug
      tagline
      targetAudience: target_audience
      ticketLink: ticket_link
      tags: event_tags {
        tag {
          name
        }
      }
    }
  }
`

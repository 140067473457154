import { gql } from '@apollo/client'

export default gql`
  query GET_ORGANIZATION_APPLICATIONS($offset: Int) {
    metaData: organization_applications_aggregate (where: {processed: {_eq: false}}){
      aggregate {
        count
      }
    }
    
    data: organization_applications (limit: 10, offset: $offset, order_by: { id: desc }, where: {processed: {_eq: false}}) {
      id
      name: organization_name
      email: contact_email
      processed
    }
  }
`

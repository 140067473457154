import { gql } from '@apollo/client'

export default gql`
  mutation ADD_ARTS_APPLICATION(
    $organizationName: String!,
    $contactName: String!,
    $contactEmail: String!,
    $organizationDescription: String!,
  ) {
    insert_organization_applications(objects: {
      contact_email: $contactEmail,
      contact_name: $contactName,
      organization_description: $organizationDescription,
      organization_name: $organizationName
    }) {
      affected_rows
    }
  }
`

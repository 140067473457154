import { gql } from '@apollo/client'

export default gql`
  mutation ADD_EVENT(
    $about: String,
    $artists: String,
    $name: String,
    $slug: String,
    $location: String,
    $scene: String,
    $maxPrice: Int,
    $minPrice: Int,
    $startDate: date,
    $endDate: date,
    $netflixShows: String,
    $organizationId: Int!,
    $tagline: String,
    $ticketLink: String,
    $isDigital: Boolean,
    $targetAudience: String,
    $tags: [event_tags_insert_input!]!
  ) {
    insert_events(objects: {
      about: $about,
      artists: $artists,
      event_tags: {
        data: $tags
      },
      name: $name,
      slug: $slug,
      scene: $scene,
      location: $location,
      max_price: $maxPrice,
      min_price: $minPrice,
      start_date: $startDate,
      end_date: $endDate,
      netflix_shows: $netflixShows,
      organization_id: $organizationId,
      tagline: $tagline,
      ticket_link: $ticketLink,
      is_digital: $isDigital,
      target_audience: $targetAudience,
    }) {
      returning {
      id
    }
    }
  }
`

import React, { useState } from 'react'
import styled from 'styled-components'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useStoreon } from 'storeon/react'

import { ADD_EVENT } from 'gql'
import { useMutation } from '@apollo/client'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Select from 'components/Select'
import slugify from 'slugify'
import { useHistory } from 'react-router-dom'
import Datetime from 'react-datetime'

const genres = [
  { label: 'Jazz', value: 'jazz' },
  { label: 'Opera', value: 'opera' },
  { label: 'Symphonic', value: 'symphonic' },
  { label: 'Theater', value: 'theater' },
  { label: 'Dance', value: 'dance' }
]

const vibes = [
  { label: 'Chill', value: 'chill' },
  { label: 'Romantic', value: 'romantic' },
  { label: 'Soulful', value: 'soulful' },
  { label: 'Sensual', value: 'sensual' },
  { label: 'Celebratory', value: 'celebratory' },
  { label: 'Enlightening', value: 'enlightening' }
]

const age = [
  { label: 'Family Friendly for all ages', value: 'Family Friendly for all ages' },
  { label: 'No kids under 5 years old', value: 'No kids under 5 years old' },
  { label: '21 years old and older only', value: '21 years old and older only' },
  { label: 'Mature Audiences', value: 'Mature Audiences' }
]

const scenes = [
  { label: 'Large Theater', value: 'Large Theater' },
  { label: 'Small Theater', value: 'Small Theater' },
  { label: 'Salon-Type Style Performance', value: 'Salon-Type Style Performance' },
  { label: 'At Someone’s Home', value: 'At Someone’s Home' },
  { label: 'Outdoors', value: 'Outdoors' },
  { label: 'Immersive Experience', value: 'Immersive Experience' },
  { label: 'Bar-Type Setting (not a sit down event)', value: 'Bar-Type Setting (not a sit down event)' },
  { label: 'Dinner Theater', value: 'Dinner Theater' },
  { label: 'Outdoors', value: 'Outdoors' }
]

const InfoText = styled.h4`
  font-weight: 300;
  margin: 10px 0px 30px;
  text-align: center;
`

export default function NewEvent () {
  const [loading, setLoading] = useState(false)
  const { user, organizations } = useStoreon('user', 'organizations')
  const [addEvent] = useMutation(ADD_EVENT)
  const [event, setEvent] = useState({
    about: '',
    artists: '',
    tagline: '',
    name: '',
    location: '',
    minPrice: 0,
    maxPrice: 0,
    eventType: null,
    ticketLink: '',
    targetAudience: '',
    scene: '',
    netflixShows: '',
    genres: [],
    vibes: [],
    startDate: new Date(),
    endDate: new Date()
  })
  const history = useHistory()

  const handleEventChange = newState => {
    setEvent(prev => ({
      ...prev,
      ...newState
    }))
  }

  const handleSubmit = async () => {
    setLoading(true)

    const token = await user.getIdToken()

    const newEvent = { ...event }
    newEvent.tags = [...newEvent.vibes, ...newEvent.genres].map(t => ({
      tag: {
        data: {
          name: t
        },
        on_conflict: {
          constraint: 'tags_name_key',
          update_columns: ['name']
        }
      }
    }))

    newEvent.slug = slugify(newEvent.name, {
      lower: true,
      strict: true
    })

    newEvent.isDigital = newEvent.eventType !== 'live'

    const data = await addEvent(({
      variables: {
        ...newEvent,
        organizationId: organizations[0]
      },
      context: {
        headers: {
          authorization: `Bearer ${token}`,
          'X-Hasura-Role': 'organization'
        }
      }
    }))

    console.log(data)

    const { id } = data.data.insert_events.returning[0]

    history.push(`/admin/events/${id}/media`)

    setLoading(false)
  }

  const renderLive = () => {
    if (event.eventType === 'digital' || !event.eventType) {
      return null
    }

    return (
      <>
        <GridItem xs={12} sm={12}>
          <InfoText>
            Where does this performance take place?
          </InfoText>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <CustomInput
            labelText='Address'
            id='location'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: event.location,
              onChange: e => handleEventChange({ location: e.target.value }),
              multiline: true
            }}
          />
        </GridItem>
      </>
    )
  }

  return (
    <GridContainer justify='center' style={{ maxWidth: 1000 }}>
      <GridItem xs={12} sm={12}>
        <InfoText style={{ marginBottom: 16 }}>
          Is this a live performance, digital performance, or both?
        </InfoText>
      </GridItem>
      <GridItem xs={4} sm={4} md={4} lg={4}>
        <Button
          fullWidth
          color={event.eventType === 'live' ? 'rose' : 'white'}
          onClick={() => handleEventChange({ eventType: 'live' })}
        >
          Live
        </Button>
      </GridItem>
      <GridItem xs={4} sm={4} md={4} lg={4}>
        <Button
          fullWidth
          color={event.eventType === 'digital' ? 'rose' : 'white'}
          onClick={() => handleEventChange({ eventType: 'digital' })}
        >
          Digital
        </Button>
      </GridItem>
      <GridItem xs={4} sm={4} md={4} lg={4}>
        <Button
          fullWidth
          color={event.eventType === 'both' ? 'rose' : 'white'}
          onClick={() => handleEventChange({ eventType: 'both' })}
        >
          Both
        </Button>
      </GridItem>
      <div style={{ height: 24, width: 1 }}/>
      <GridItem xs={12} sm={12}>
        <InfoText>
          What is your event called and how would you describe it in 60 characters or less?
        </InfoText>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={6}>
        <CustomInput
          labelText='Event Name'
          id='name'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: event.name,
            onChange: e => handleEventChange({ name: e.target.value }),
            maxLength: 60
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={6}>
        <CustomInput
          labelText='Tagline'
          id='tagline'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: event.tagline,
            onChange: e => handleEventChange({ tagline: e.target.value }),
            maxLength: 60
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12}>
        <InfoText>
          When's the first and last day your event takes place?
        </InfoText>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={6}>
        <Datetime
          timeFormat={false}
          inputProps={{ placeholder: 'First Day' }}
          value={event.startDate}
          onChange={value => handleEventChange({ startDate: value, endDate: value })}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={6}>
        <Datetime
          timeFormat={false}
          inputProps={{ placeholder: 'Last Day' }}
          value={event.endDate}
          onChange={value => handleEventChange({ endDate: value })}
        />
      </GridItem>
      <GridItem xs={12} sm={12} style={{ margin: '16px 0px' }}>
        <InfoText>
          What genres and vibes fit your event the best? Select as many as applicable.
        </InfoText>
      </GridItem>
      <GridItem xs={12} sm={12} lg={6}>
        <Select
          multiple
          label='Genres'
          value={event.genres}
          onChange={e => handleEventChange({ genres: e.target.value })}
          options={genres}
        />
      </GridItem>
      <GridItem xs={12} sm={12} lg={6}>
        <Select
          multiple
          label='Vibes'
          value={event.vibes}
          onChange={e => handleEventChange({ vibes: e.target.value })}
          options={vibes}
        />
      </GridItem>
      <GridItem xs={12} sm={12} style={{ margin: '16px 0px' }}>
        <InfoText>
          Where can people buy tickets and what's the price range of them?
        </InfoText>
      </GridItem>
      <GridItem xs={12} sm={12} lg={8}>
        <CustomInput
          labelText={'Ticketing Link'}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            value: event.ticketLink,
            onChange: e => handleEventChange({ ticketLink: e.target.value })
          }}
        />
      </GridItem>
      <GridItem xs={6} sm={6} lg={2}>
        <CustomInput
          labelText={'Min Price'}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: e => handleEventChange({ minPrice: +e.target.value, maxPrice: +e.target.value }),
            value: event.minPrice,
            type: 'number'
          }}
        />
      </GridItem>
      <GridItem xs={6} sm={6} lg={2}>
        <CustomInput
          labelText={'Max Price'}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: e => handleEventChange({ maxPrice: +e.target.value }),
            value: event.maxPrice,
            type: 'number'
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} style={{ margin: '16px 0px' }}>
        <InfoText>
          What age range would you recommend for your event and what type of scene does it take place in?
        </InfoText>
      </GridItem>
      <GridItem xs={12} sm={12} lg={6}>
        <Select
          label='targetAudience'
          value={event.targetAudience}
          onChange={e => handleEventChange({ targetAudience: e.target.value })}
          options={age}
        />
      </GridItem>
      <GridItem xs={12} sm={12} lg={6}>
        <Select
          label='Scene Type'
          value={event.scene}
          onChange={e => handleEventChange({ scene: e.target.value })}
          options={scenes}
        />
      </GridItem>
      <GridItem xs={12} sm={12}>
        <InfoText>
          Please list 2-3 current Netflix shows or types of popular bands that correspond with your event.
        </InfoText>
      </GridItem>
      <GridItem xs={12} sm={12} lg={12}>
        <CustomInput
          labelText={'If you like that, you\'ll love this this'}
          id='netflixShows'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: e => handleEventChange({ netflixShows: e.target.value }),
            multiline: true
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} style={{ margin: '16px 0px' }}>
        <InfoText>
          If you were to tell your friend about this event, how would you describe it (layman’s terms, please! And please do not just copy and paste what is on your website. Break it down for us and give us the “gist” and key details that we should know)
        </InfoText>
      </GridItem>
      <GridItem xs={12} sm={12} lg={12}>
        <CustomInput
          labelText='About'
          id='email'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: e => handleEventChange({ about: e.target.value }),
            multiline: true
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} style={{ margin: '16px 0px' }}>
        <InfoText>
          Will there be any special guest artists? If so, who? (please link their website or social media page if they have one)
        </InfoText>
      </GridItem>
      <GridItem xs={12} sm={12} lg={12}>
        <CustomInput
          labelText='Guest artists'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: e => handleEventChange({ artists: e.target.value }),
            multiline: true
          }}
        />
      </GridItem>
      {renderLive()}
      <GridItem xs={12} sm={12} lg={12}>
        <Button
          fullWidth
          color={'rose'}
          disabled={loading}
          onClick={handleSubmit}
        >
          Create Event & Add Photos
        </Button>
      </GridItem>
    </GridContainer>
  )
}

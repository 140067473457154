import { useEffect, useState } from 'react'
import store from '../stores'
import queryString from 'query-string'
import request from 'superagent'
import config from 'config'

const { rcaApi } = config

export * from './queryString'

export const { dispatch, get: getStore } = store

export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },

    [ref, handler]
  )
}

export const genImagePath = ({ ownerType, ownerId, name, id }) => {
  return `https://rca-s3.imgix.net/${ownerType}/${ownerId}/${id}${name}`
}

export const uploadFiles = async ({ ownerId, ownerType, files, token }) => {
  try {
    const massaagedFiles = files.map(p => {
      const fileArr = p.file.name.split('.')
      const fileExt = fileArr[fileArr.length - 1]

      return {
        fileExt,
        contentType: p.file.type,
        contentLength: p.file.size
      }
    })

    const { body } = await request
      .post(`${rcaApi}/files`)
      .set('Authorization', token)
      .send({
        ownerId,
        ownerType,
        files: massaagedFiles
      })

    for (let i = 0; i < body.urls.length; i++) {
      await request
        .put(body.urls[i].url)
        .send(files[i].file)
    }

    return body
  } catch (e) {
    const { message } = e

    if (message) {
      window.alert(message)
    }

    return false
  }
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay]
  )

  return debouncedValue
}

export const imgixUrl = (path, options) => {
  const qs = options ? `?${queryString.stringify(options)}` : ''
  return `https://rca-s3.imgix.net/${path}${qs}`
}

export const datesToString = ({ startDate, endDate, dateFormat = 'MMM Do' }) => {
  const startDateString = startDate && startDate.format(dateFormat)
  const endDateString = endDate && endDate.format(dateFormat)

  return (`${startDateString || ''}${endDateString ? `- ${endDateString}` : ''}`)
}

import { gql } from '@apollo/client'

export default gql`
  query GET_ORGANIZATION_APPLICATION($id: Int) {
    data: organization_applications (limit: 1, where: {id: {_eq: $id}}) {
      contactEmail: contact_email
      contactName: contact_name
      createdAt: created_at
      id
      description: organization_description
      orgName: organization_name
      uid: user_uid
      processed
      user {
        email
      }
    }
  }
`

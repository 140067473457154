import { gql } from '@apollo/client'

export default gql`
  mutation DELETE_EVENT_IMAGE(
    $id: Int!
  ) {
    data: update_event_images_by_pk (
      pk_columns: { id: $id },
      _set: {
        is_deleted: true,
      }
    ) {
      id
    }
  }
`

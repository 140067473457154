import request from 'superagent'
import config from 'config'

const { rcaApi } = config

export const approveOrganization = async ({ token, id, uid, slug, name, email }) => {
  try {
    const { body } = await request
      .post(`${rcaApi}/approve-organization/${id}`)
      .set('Authorization', token)
      .send({
        name,
        slug,
        uid,
        email
      })
      .ok(res => res.status < 500)

    return body
  } catch (e) {
    const { message } = e

    if (message) {
      window.alert(message)
    }

    return false
  }
}

export const uploadOrganizationPhotos = async ({ files, organizationId, token }) => {
  try {
    const massaagedFiles = files.map(p => {
      const fileArr = p.file.name.split('.')
      const fileType = fileArr[fileArr.length - 1]

      return {
        fileType,
        contentType: p.file.type
      }
    })

    const { body } = await request
      .post(`${rcaApi}/organizations/gallery-upload-urls`)
      .set('Authorization', token)
      .send({ files: massaagedFiles, organizationId })

    const imagePaths = []

    for (let i = 0; i < body.urls.length; i++) {
      await request
        .put(body.urls[i].url)
        .send(files[i].file)

      imagePaths.push(body.urls[i].path)
    }

    await request
      .post(`${rcaApi}/organizations/gallery-confirm`)
      .set('Authorization', token)
      .send({ imagePaths, organizationId })

    return true
  } catch (e) {
    const { message } = e

    if (message) {
      window.alert(message)
    }

    return false
  }
}

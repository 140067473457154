import React from 'react'
import GqlTable from 'components/GqlTable'
import { GET_EPISODES } from 'gql'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'

const Organizations = () => {
  const history = useHistory()

  return (
    <GqlTable
      config={{
        icon: <VideoLibraryIcon />,
        title: 'Episodes',
        columns: ['id', 'name', 'createdAt'],
        labels: ['Id', 'Name', 'Created At'],
        gql: GET_EPISODES,
        to: data => `/admin/episodes/${data.id}`,
        toText: 'View Episode',
        customValue: {
          createdAt: v => moment(new Date(v)).format('LLL')
        }
      }}
      history={history}
    />
  )
}

export default Organizations

import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
}

class Step2 extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      phone: '',
      email: '',
      boxOfficeHours: '',
      facebook: '',
      instagram: '',
      website: '',
      youtube: '',
      twitter: ''
    }
  }

  sendState () {
    return this.state
  }

  change (event, stateName) {
    this.setState({ [stateName]: event.target.value })
  }

  render () {
    const { classes } = this.props
    return (
      <GridContainer justify='center' style={{ maxWidth: 600 }}>
        <GridItem xs={10} sm={10}>
          <h4 className={classes.infoText}>
            Give us information that'll be useful for your future audience. You can leave any fields that don't apply to you blank.
          </h4>
        </GridItem>
        <GridContainer justify='center'>
          <GridItem xs={10} sm={10} md={5}>
            <CustomInput
              labelText='Public Phone Number'
              formControlProps={{ fullWidth: true }}
              inputProps={{ onChange: event => this.change(event, 'lastname', 'length', 3) }}
            />
          </GridItem>
          <GridItem xs={10} sm={10} md={5}>
            <CustomInput
              labelText='Public Email'
              formControlProps={{ fullWidth: true }}
              inputProps={{ onChange: event => this.change(event, 'lastname', 'length', 3) }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify='center'>
          <GridItem xs={10} sm={10} md={10}>
            <CustomInput
              labelText='Website'
              formControlProps={{ fullWidth: true }}
              inputProps={{ onChange: event => this.change(event, 'lastname', 'length', 3) }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify='center'>
          <GridItem xs={10} sm={10} md={5}>
            <CustomInput
              labelText='Facebook'
              formControlProps={{ fullWidth: true }}
              inputProps={{ onChange: event => this.change(event, 'lastname', 'length', 3) }}
            />
          </GridItem>
          <GridItem xs={10} sm={10} md={5}>
            <CustomInput
              labelText='Instagram'
              formControlProps={{ fullWidth: true }}
              inputProps={{ onChange: event => this.change(event, 'lastname', 'length', 3) }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify='center'>
          <GridItem xs={10} sm={10} md={5}>
            <CustomInput
              labelText='Youtube'
              formControlProps={{ fullWidth: true }}
              inputProps={{ onChange: event => this.change(event, 'lastname', 'length', 3) }}
            />
          </GridItem>
          <GridItem xs={10} sm={10} md={5}>
            <CustomInput
              labelText='Twitter'
              formControlProps={{ fullWidth: true }}
              inputProps={{ onChange: event => this.change(event, 'lastname', 'length', 3) }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify='center'>
          <GridItem xs={10} sm={10} md={10}>
            <CustomInput
              labelText='Box Office Hours'
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event => this.change(event, 'lastname', 'length', 3),
                multiline: true
              }}
            />
          </GridItem>
        </GridContainer>
      </GridContainer>
    )
  }
}

Step2.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(Step2)

import React from 'react'
import GqlDetails from 'components/GqlDetails'
import { GET_ARTICLE, UPDATE_ORGANIZATION } from 'gql'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'

const Organizations = () => {
  return (
    <GqlDetails
      config={{
        icon: <ImportContactsIcon />,
        gqlGet: GET_ARTICLE,
        gqlPut: UPDATE_ORGANIZATION,
        dataName: 'article',
        formRows: [
          [
            { label: 'Title', id: 'title', adminOnly: true, type: 'input' },
            { label: 'Slug', id: 'slug', adminOnly: true, type: 'input' }
          ]
        ]
      }}
    />
  )
}

export default Organizations

import React from 'react'
// react plugin for creating charts
import ChartistGraph from 'react-chartist'
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import PersonAdd from '@material-ui/icons/PersonAdd'
// import InfoOutline from "@material-ui/icons/InfoOutline";
import DateRange from '@material-ui/icons/DateRange'
import Update from '@material-ui/icons/Update'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import AccessTime from '@material-ui/icons/AccessTime'
import Refresh from '@material-ui/icons/Refresh'
import Edit from '@material-ui/icons/Edit'
import Face from '@material-ui/icons/Face'
import Assignment from '@material-ui/icons/Assignment'
import Share from '@material-ui/icons/Share'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from 'variables/charts'

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'

const useStyles = makeStyles(styles)

export default function Dashboard () {
  const classes = useStyles()
  return (
    <div>
      <h1>Demo Overview</h1>
      <h3>Real stats coming later, other parts of the dashboard are connected to real data.</h3>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color='warning' stats icon>
              <CardIcon color='warning'>
                <Assignment />
              </CardIcon>
              <p className={classes.cardCategory}>New Applications</p>
              <h3 className={classes.cardTitle}>
                +10
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color='success' stats icon>
              <CardIcon color='success'>
                <Face />
              </CardIcon>
              <p className={classes.cardCategory}>New Artists</p>
              <h3 className={classes.cardTitle}>+30</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color='danger' stats icon>
              <CardIcon color='danger'>
                <Share />
              </CardIcon>
              <p className={classes.cardCategory}>Social Media Shares</p>
              <h3 className={classes.cardTitle}>500</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last hour
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color='info' stats icon>
              <CardIcon color='info'>
                <PersonAdd />
              </CardIcon>
              <p className={classes.cardCategory}>New Users</p>
              <h3 className={classes.cardTitle}>+100</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color='info' className={classes.cardHeaderHover}>
              <ChartistGraph
                className='ct-chart-white-colors'
                data={dailySalesChart.data}
                type='Line'
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id='tooltip-top'
                  title='Refresh'
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color='info' justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id='tooltip-top'
                  title='Change Date'
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color='transparent' simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Daily Views</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{' '}
                increase in today views.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 10 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color='warning' className={classes.cardHeaderHover}>
              <ChartistGraph
                className='ct-chart-white-colors'
                data={emailsSubscriptionChart.data}
                type='Bar'
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id='tooltip-top'
                  title='Refresh'
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color='info' justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id='tooltip-top'
                  title='Change Date'
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color='transparent' simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Ticket Sales</h4>
              <p className={classes.cardCategory}>This years's ticket sales</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 1 hour ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color='danger' className={classes.cardHeaderHover}>
              <ChartistGraph
                className='ct-chart-white-colors'
                data={completedTasksChart.data}
                type='Line'
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id='tooltip-top'
                  title='Refresh'
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color='info' justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id='tooltip-top'
                  title='Change Date'
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color='transparent' simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Hourly Views</h4>
              <p className={classes.cardCategory}>Today's views by hour</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

import React, { useEffect } from 'react'
import { getQuery, updateQuery } from 'utils'
import { useStoreon } from 'storeon/react'
import { useLazyQuery } from '@apollo/client'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardBody from 'components/Card/CardBody.js'
import Table from 'components/Table/Table.js'
import Pagination from 'components/Pagination'
import { Link } from 'react-router-dom'

const GqlTable = props => {
  const { history, config } = props
  const { columns, labels, to, gql, customValue, toText, icon } = config
  const query = getQuery(history)
  const { page = 1 } = query
  const { user, isAdmin } = useStoreon('isAdmin', 'user')
  const [getData, { loading, data }] = useLazyQuery(gql)

  useEffect(() => {
    (async () => {
      if (user) {
        const token = await user.getIdToken()

        getData({
          variables: { offset: (page - 1) * 10 },
          context: {
            headers: {
              authorization: `Bearer ${token}`,
              'X-Hasura-Role': isAdmin ? 'rca' : 'organization'
            }
          }
        })
      }
    })()
  }, [page, getData, isAdmin, user])

  if (!user || loading || !data) {
    return null
  }

  const { data: rowData, metaData: meta } = data
  const { count } = meta.aggregate

  const tableData = rowData.map(r => {
    const row = columns.map(c => {
      let val = r[c]

      if (customValue && customValue[c]) {
        val = customValue[c](val)
      }

      return val
    })

    if (to) {
      row.push((
        <Link to={to(r)}>
          {toText}
        </Link>
      ))
    }

    return row
  })

  const onPageChange = value => {
    updateQuery(history, 'page', value, { pagination: true })
  }

  return (
    <GridContainer justify='center'>
      <GridItem xs={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              {icon}
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Table
              rowLinks
              tableHeaderColor='primary'
              tableHead={labels}
              tableData={tableData}
            />
          </CardBody>
        </Card>
      </GridItem>
      <Pagination
        page={page}
        count={count}
        onChange={onPageChange}
      />
    </GridContainer>
  )
}

export default GqlTable

import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Dropzone from 'react-dropzone'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import styled from 'styled-components'
import Close from '@material-ui/icons/Close'

const ImagePreview = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;

  > div {
    &:first-child {
      height: 100%;
      width: 100%;
      background-image: url(${({ image }) => image});
      background-size: cover;
    }

    &:last-child {
      position: absolute;
      top: 8px;
      right: 8px;

      > svg {
        height: 24px;
        width: 24px;
        color: white;
      }
    }
  }
`

const ImagePreviews = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
`

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  }
}

export const DropzoneWrapper = styled.div`
  height: 100px;
  width: 100%;
  max-width: 100%;
  border: 1px dashed #6D6A6A;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 16px 0px;
  cursor: pointer;
`

class Step3 extends React.Component {
  constructor (props) {
    super(props)

    this.onDrop = (files) => {
      this.setState(prev => ({
        ...prev,
        files: [
          ...prev.files,
          ...files.map(af => ({
            file: af,
            preview: URL.createObjectURL(af)
          }))
        ]
      }))
    }

    this.state = {
      files: []
    }

    this.onRemove = this.onRemove.bind(this)
    this.renderImage = this.renderImage.bind(this)
  }

  sendState () {
    return this.state
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value })
  };

  isValidated () {
    if (!this.state.files.length > 3) {
      alert('Please upload at least 4 pictures')
    }

    return this.state.files.length > 3
  }

  onRemove (idxToRemove) {
    this.setState(prev => ({
      ...prev,
      files: prev.files.filter((item, idx) => idx !== idxToRemove)
    }))
  }

  renderImage (item, idx) {
    return (
      <ImagePreview key={idx} image={item.preview} onClick={() => this.onRemove(idx)}>
        <div />
        <div>
          <Close />
        </div>
      </ImagePreview>
    )
  }

  render () {
    const { classes } = this.props

    return (
      <GridContainer justify='center' style={{ maxWidth: 600 }}>
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Upload at least four pictures of your organization.</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={11}>
          <ImagePreviews>
            {this.state.files.map(this.renderImage)}
          </ImagePreviews>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={11}>
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <DropzoneWrapper {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </DropzoneWrapper>
            )}
          </Dropzone>
        </GridItem>
      </GridContainer>
    )
  }
}

Step3.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(Step3)

import { gql } from '@apollo/client'

export default gql`
  mutation ADD_TAG($tagName: String!) {
    tag: insert_tags(on_conflict: {constraint: tags_name_key, update_columns: [name]}, objects: [{name: $tagName}]) {
      affected_rows
      returning {
        id
      }
    }
  }
`

import React from 'react'
import GqlTable from 'components/GqlTable'
import { GET_ORGANIZATIONS } from 'gql'
import { useHistory } from 'react-router-dom'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import moment from 'moment'

const Organizations = () => {
  const history = useHistory()

  return (
    <GqlTable
      config={{
        icon: <RecentActorsIcon />,
        title: 'Organizations',
        columns: ['id', 'name', 'createdAt', 'status'],
        labels: ['Id', 'Name', 'Created At', 'Status'],
        gql: GET_ORGANIZATIONS,
        to: data => `/admin/organizations/${data.id}`,
        toText: 'View Organization',
        customValue: {
          createdAt: v => moment(new Date(v)).format('LLL'),
          status: v => {
            switch (v) {
              case 0:
                return 'Not Live'
              case 1:
                return 'Live'
              default:
                return 'Not Live'
            }
          }
        }
      }}
      history={history}
    />
  )
}

export default Organizations

import { gql } from '@apollo/client'

export default gql`
  query GET_ARTICLES ($offset: Int) {
    metaData: articles_aggregate {
      aggregate {
        count
      }
    }
    
    data: articles (limit: 10, offset: $offset, order_by: { date: desc }) {
      id
      title
      updatedAt: updated_at
      date
    }
  }
`

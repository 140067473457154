import { gql } from '@apollo/client'

export default gql`
  mutation UPDATE_ORGANIZATION(
    $id: Int!
    $email: String,
    $facebook: String,
    $boxOfficeHours: String,
    $about: String,
    $instagram: String,
    $phone: String,
    $tagline: String,
    $twitter: String,
    $website: String,
    $youtube: String
  ) {
    data: update_organizations_by_pk (
      pk_columns: { id: $id },
      _set: {
        email: $email,
        facebook: $facebook,
        box_office_hours: $boxOfficeHours,
        about: $about,
        instagram: $instagram,
        onboarding_complete: true,
        phone: $phone,
        tagline: $tagline,
        twitter: $twitter,
        website: $website,
        youtube: $youtube
      }
    ) {
      id
    }
  }
`

import { gql } from '@apollo/client'

export default gql`
  query GET_EPISODE($id: Int!) {
    episode: episodes_by_pk(id: $id) {
      date
      id
      slug
      name
      url
      description
      episode_tags {
        id
      }
    }
  }
`

import { gql } from '@apollo/client'

export default gql`
  query GET_ORGANIZATION_GALLERY($id: Int!) {
    data: organization_images (
      where: {
        is_deleted: { _eq: false },
        organization_id: { _eq: $id }
      }
    ) {
      id
      path
      priority
      type
    }
  }
`

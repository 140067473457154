import React from 'react'
import GqlDetails from 'components/GqlDetails'
import { GET_EVENT, UPDATE_EVENT } from 'gql'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import styled from 'styled-components'
import Button from 'components/CustomButtons/Button.js'
import { useParams } from 'react-router-dom'

const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  display: flex;
`

const age = [
  { label: 'Family Friendly for all ages', value: 'Family Friendly for all ages' },
  { label: 'No kids under 5 years old', value: 'No kids under 5 years old' },
  { label: '21 years old and older only', value: '21 years old and older only' },
  { label: 'Mature Audiences', value: 'Mature Audiences' }
]

const scenes = [
  { label: 'Large Theater', value: 'Large Theater' },
  { label: 'Small Theater', value: 'Small Theater' },
  { label: 'Salon-Type Style Performance', value: 'Salon-Type Style Performance' },
  { label: 'At Someone’s Home', value: 'At Someone’s Home' },
  { label: 'Outdoors', value: 'Outdoors' },
  { label: 'Immersive Experience', value: 'Immersive Experience' },
  { label: 'Bar-Type Setting (not a sit down event)', value: 'Bar-Type Setting (not a sit down event)' },
  { label: 'Dinner Theater', value: 'Dinner Theater' },
  { label: 'Outdoors', value: 'Outdoors' }
]

const status = [
  { label: 'Unpublished', value: '0' },
  { label: 'Published', value: '1' }
]

const Event = () => {
  const { id } = useParams()

  return (
    <EventWrapper>
      <GqlDetails
        config={{
          icon: <RecentActorsIcon />,
          gqlGet: GET_EVENT,
          gqlPut: UPDATE_EVENT,
          dataName: 'event',
          formRows: [
            [
              { label: 'Name', id: 'name', adminOnly: true, type: 'input' },
              { label: 'Tagline', id: 'tagline', type: 'input' }
            ],
            [
              { label: 'First Day', id: 'startDate', type: 'date' },
              { label: 'Last Day', id: 'endDate', type: 'date' },
              { label: 'Status', id: 'status', type: 'select', options: status }
            ],
            [
              { label: 'Ticketing Link', id: 'ticketLink', type: 'input' },
              { label: 'Min Price', id: 'minPrice', type: 'input' },
              { label: 'Max Price', id: 'maxPrice', type: 'input' }
            ],
            [
              { label: 'Target Audience', id: 'targetAudience', type: 'select', options: age },
              { label: 'Scene Type', id: 'scene', type: 'select', options: scenes }
            ],
            [
              { label: 'Netflix Shows', id: 'netflixShows', type: 'input' }
            ],
            [
              { label: 'About', id: 'about', type: 'input', multiline: true }
            ],
            [
              { label: 'Guest Artists', id: 'artists', type: 'input', multiline: true }
            ],
            [
              { label: 'Location', id: 'location', type: 'input' }
            ]
          ]
        }}
      />
      <ButtonWrapper>
        <Button color='rose' to={`/admin/events/${id}/media`}>
          Manage Images
        </Button>
      </ButtonWrapper>
    </EventWrapper>
  )
}

export default Event

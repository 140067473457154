import { gql } from '@apollo/client'

export default gql`
  query GET_ARTICLE($id: Int!) {
    article: articles_by_pk(id: $id) {
      title
      slug
      date
      defaultImage: default_image,
      article_tags {
        id
      }
    }
  }
`

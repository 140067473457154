import React, { useState, useEffect, createRef } from 'react'
import cx from 'classnames'
import { Switch, Route, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import { orgRoutes, rcaRoutes } from 'routes.js'
import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import Email from '@material-ui/icons/Email'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import styled from 'styled-components'
import { useStoreon } from 'storeon/react'
import Snackbars from 'components/Snackbar/Snackbar.js'
import { getMe } from 'api'

const FormWrapper = styled.form`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RcaLogo = styled.div`
  height: 200px;
  width: 100%;
  background-image: url('http://red-curtain-addict.imgix.net/static/rca.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const useStyles = makeStyles(styles)

export default function Dashboard (props) {
  const { ...rest } = props
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false)
  const [miniActive, setMiniActive] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [bgColor] = useState('black')
  const [logo] = useState(require('assets/img/logo-white.svg'))
  // styles
  const classes = useStyles()
  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1
    })
  // ref for main panel div
  const mainPanel = createRef()
  const [user, loading] = useAuthState(firebase.auth())
  const { dispatch, isAdmin, user: storeonUser, loading: storeonLoading, notification } = useStoreon('isAdmin', 'user', 'loading', 'notification')

  useEffect(() => {
    (async () => {
      if (user && !loading) {
        const token = await user.getIdToken()
        const res = await getMe({ token })
        dispatch('setUserState', { ...res, user, loading })
      } else {
        dispatch('setUserState', { user, loading })
      }
    })()
  }, [user, loading, dispatch])

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    window.addEventListener('resize', resizeFunction)

    // Specify how to clean up after this effect:
    return function cleanup () {
      window.removeEventListener('resize', resizeFunction)
    }
  })

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps'
  }
  const getActiveRoute = routes => {
    const activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }

      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      )
    })
  }
  const sidebarMinimize = () => {
    setMiniActive(!miniActive)
  }
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  const onSubmit = async e => {
    e.preventDefault()

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (error) {
      alert('The email and password provided do not match.')
    }
  }

  const renderBody = () => {
    if (storeonLoading) {
      return null
    }

    if (!storeonUser) {
      return (
        <GridContainer justify="center" style={{
          height: '100%',
          backgroundImage: 'url(https://red-curtain-addict.imgix.net/static/banner.jpg?auto=compression&height=1600&blur=100)',
          backgroundSize: 'cover'
        }}>
          <FormWrapper onSubmit={onSubmit}>
            <Card login>
              <CardBody>
                <RcaLogo />
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => setEmail(e.target.value),
                    value: email,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => setPassword(e.target.value),
                    value: password,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password'
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" block type="submit">
                    Login
                </Button>
              </CardFooter>
            </Card>
          </FormWrapper>
        </GridContainer>
      )
    }

    const routes = isAdmin ? rcaRoutes : orgRoutes

    return (
      <>
        <Sidebar
          routes={routes}
          logo={logo}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={'rose'}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/admin" to={isAdmin ? '/admin/overview' : '/admin/profile'} />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to={isAdmin ? '/admin/overview' : '/admin/profile'} />
              </Switch>
            </div>
          )}
        </div>
        <Snackbars
          place='bl'
          color={notification ? notification.color : undefined}
          open={!!notification}
          message={notification ? notification.message : ''}
          close
          closeNotification={() => dispatch('setNotification', null)}
        />
      </>
    )
  }

  return (
    <div className={classes.wrapper}>
      {renderBody()}
    </div>
  )
}

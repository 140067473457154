import React from 'react'
import GqlTable from 'components/GqlTable'
import { GET_ARTICLES } from 'gql'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'

const Articles = () => {
  const history = useHistory()

  return (
    <GqlTable
      config={{
        icon: <ImportContactsIcon />,
        title: 'Articles',
        columns: ['title', 'date', 'updatedAt'],
        labels: ['Title', 'Date', 'Last updated'],
        gql: GET_ARTICLES,
        to: data => `/admin/articles/${data.id}`,
        toText: 'View Article',
        customValue: {
          date: v => moment(new Date(v)).format('LLL'),
          updatedAt: v => moment(new Date(v)).format('LLL')
        }
      }}
      history={history}
    />
  )
}

export default Articles

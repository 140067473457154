import { gql } from '@apollo/client'

export default gql`
  query GET_ORGANIZATIONS($offset: Int) {
    metaData: organizations_aggregate {
      aggregate {
        count
      }
    }
    
    data: organizations (limit: 10, offset: $offset, order_by: { id: desc }) {
      name
      id
      slug
      createdAt: created_at
      status
    }
  }
`

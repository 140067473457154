import React from 'react'
import GqlTable from 'components/GqlTable'
import { GET_ORGANIZATION_APPLICATIONS } from 'gql'
import { useHistory } from 'react-router-dom'
import Assignment from '@material-ui/icons/Assignment'

const Applications = () => {
  const history = useHistory()

  return (
    <GqlTable
      config={{
        icon: <Assignment />,
        title: 'Pending Applications',
        columns: ['id', 'name', 'email'],
        labels: ['Id', 'Name', 'Email'],
        gql: GET_ORGANIZATION_APPLICATIONS,
        to: data => `/admin/applications/${data.id}`,
        toText: 'Review Application'
      }}
      history={history}
    />
  )
}

export default Applications

import { gql } from '@apollo/client'

export default gql`
  query GET_EVENT_IMAGES($id: Int!) {
    eventImages: event_images(where: {
      event_id: {_eq: $id},
      is_deleted: { _eq: false }
    }) {
      id
      type
      file {
        id
        ownerId: owner_id
        ownerType: owner_type
        name
      }
    }
  }
`

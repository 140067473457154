import { gql } from '@apollo/client'

export default gql`
  mutation UPDATE_EVENT(
    $about: String,
    $artists: String,
    $name: String,
    $slug: String,
    $location: String,
    $maxPrice: Int,
    $minPrice: Int,
    $startDate: date,
    $endDate: date,
    $netflixShows: String,
    $tagline: String,
    $ticketLink: String,
    $isDigital: Boolean,
    $scene: String,
    $status: Int,
    $targetAudience: String,
    $id: Int!
  ) {
    data: update_events_by_pk (
      pk_columns: { id: $id },
      _set: {
        about: $about,
        artists: $artists,
        name: $name,
        slug: $slug,
        status: $status,
        scene: $scene,
        location: $location,
        max_price: $maxPrice,
        min_price: $minPrice,
        start_date: $startDate,
        end_date: $endDate,
        netflix_shows: $netflixShows,
        tagline: $tagline,
        ticket_link: $ticketLink,
        is_digital: $isDigital,
        target_audience: $targetAudience,
      }
    ) {
      id
    }
  }
`

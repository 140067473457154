import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import AdminLayout from 'layouts/Admin.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0'
import 'semantic-ui-css/semantic.min.css'
import client from './apollo'
import createStore from './stores'
import { ApolloProvider } from '@apollo/client'
import { StoreContext } from 'storeon/react'

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBLhZrG4sB42R_xkwMSgoDoWF1gamxh5jc',
    authDomain: 'dashboard.redcurtainaddict.com',
    databaseURL: 'https://red-curtain-addict-default-rtdb.firebaseio.com/',
    projectId: 'red-curtain-addict',
    storageBucket: 'red-curtain-addict.appspot.com',
    messagingSenderId: '484768154550'
  })
}

const hist = createBrowserHistory()
const store = createStore()

ReactDOM.render(
  <ApolloProvider client={client}>
    <StoreContext.Provider value={store}>
      <Router history={hist}>
        <Switch>
          <Route path='/admin' component={AdminLayout} />
          <Redirect from='/' to='/admin/dashboard' />
        </Switch>
      </Router>
    </StoreContext.Provider>
  </ApolloProvider>
  ,
  document.getElementById('root')
)

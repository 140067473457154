import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { useStoreon } from 'storeon/react'
import { GalleryWrapper, ImagePreview, UploadGalleryItems, GalleryItems, DropzoneWrapper, ImageCard } from './styled'

import { GET_EVENT_IMAGES, ADD_EVENT_IMAGES, DELETE_EVENT_IMAGE } from 'gql'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Select from 'components/Select'
import slugify from 'slugify'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import { useDropzone } from 'react-dropzone'
import { uploadFiles, genImagePath } from 'utils'

const TitleHeader = styled.h1`
  
`

export default function EventImages () {
  const [loading, setLoading] = useState(false)
  const { user, isAdmin, organizations } = useStoreon('user', 'isAdmin', 'organizations')
  const [getData, { data }] = useLazyQuery(GET_EVENT_IMAGES)
  const [coverImage, setCoverImage] = useState(null)
  const [galleryImages, setGalleryImages] = useState([])
  const [photos, setPhotos] = useState([])
  const { id } = useParams()
  const [addImages] = useMutation(ADD_EVENT_IMAGES)
  const [deleteImage] = useMutation(DELETE_EVENT_IMAGE)

  const onRemove = idxToRemove => {
    setPhotos(prev => prev.filter((item, idx) => idx !== idxToRemove))
  }

  const renderPhoto = (item, idx) => {
    return (
      <ImagePreview key={idx} image={item.imgUrl} onClick={() => onRemove(idx)}>
        <div />
        <div>
          {/* <Icon name='close' /> */}
        </div>
      </ImagePreview>
    )
  }

  const onDelete = async id => {
    const token = await user.getIdToken()

    await deleteImage(({
      variables: { id },
      context: {
        headers: {
          authorization: `Bearer ${token}`,
          'X-Hasura-Role': 'organization'
        }
      }
    }))

    window.location.reload()
  }

  const renderImage = (item, idx) => {
    return (
      <ImageCard key={idx} image={item.imgUrl}>
        <div />
        <div>
          <Button color='danger' onClick={() => onDelete(item.id)}>
            Delete
          </Button>
          <Button color='info'>
            <a target='_blank' rel='noopener noreferrer' href={item.imgUrl}>
              View
            </a>
          </Button>
        </div>
      </ImageCard>
    )
  }

  const onGalleryUpload = async () => {
    try {
      setLoading(true)

      const token = await user.getIdToken()

      const { urls } = await uploadFiles({
        ownerId: id,
        ownerType: 1,
        files: photos,
        token
      })

      await addImages(({
        variables: {
          images: urls.map(pd => ({
            event_id: id,
            file_id: pd.id,
            type: 1
          }))
        },
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            'X-Hasura-Role': 'organization'
          }
        }
      }))

      window.location.reload()
    } catch (e) {
      alert(e)
      setLoading(false)
    }
  }

  const onGalleryDrop = useCallback(acceptedFiles => {
    setPhotos(prev => [...prev, ...acceptedFiles.map(af => ({
      file: af,
      imgUrl: URL.createObjectURL(af)
    }))])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onGalleryDrop,
    accept: 'image/*'
  })

  useEffect(() => {
    if (data) {
      const { eventImages } = data
      const galleryArr = []

      eventImages.forEach(ei => {
        if (ei.type === 0) {
          setCoverImage(ei)
        } else {
          galleryArr.push({
            ...ei,
            imgUrl: genImagePath({
              ownerId: ei.file.ownerId,
              ownerType: ei.file.ownerType,
              name: ei.file.name,
              id: ei.file.id
            })
          })
        }
      })

      setGalleryImages(galleryArr)
    }
  }, [data])

  useEffect(() => {
    (async () => {
      if (user) {
        const token = await user.getIdToken()

        getData({
          variables: { id },
          context: {
            headers: {
              authorization: `Bearer ${token}`,
              'X-Hasura-Role': isAdmin ? 'rca' : 'organization'
            }
          }
        })
      }
    })()
  }, [getData, isAdmin, user, id])

  return (
    <GridContainer justify='center' style={{ maxWidth: 1000 }}>
      <GridItem xs={12} sm={12}>
        <TitleHeader>
          Gallery Images
        </TitleHeader>
      </GridItem>
      <GalleryItems>
        {galleryImages.map(renderImage)}
      </GalleryItems>
      <GridItem xs={12} sm={12}>
        <TitleHeader>
          Add More Gallery Images
        </TitleHeader>
      </GridItem>
      <GridItem xs={12} sm={12}>
        <UploadGalleryItems>
          {photos.map(renderPhoto)}
        </UploadGalleryItems>
      </GridItem>
      <GridItem xs={12} sm={12}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <DropzoneWrapper>
            {
              isDragActive
                ? <p>Drop the files here ...</p>
                : <p>Drag 'n' drop some files here, or click to select files</p>
            }
          </DropzoneWrapper>
        </div>
      </GridItem>
      {loading && <span>Please wait...</span>}
      <Button disabled={photos.length === 0 || loading} color='rose' loading={loading} onClick={onGalleryUpload}>Upload New Gallery Images</Button>
    </GridContainer>
  )
}

export default {
  initialState: {
    user: null,
    loading: true,
    hasDashboardAccess: false,
    roles: [],
    isAdmin: false
  },
  actions: [
    {
      name: 'setUserState',
      cb: (_, value) => {
        return value
      }
    }
  ]
}

import Scenes from 'scenes'

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard'
// import Image from '@material-ui/icons/Image'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import Assignment from '@material-ui/icons/Assignment'
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber'
import Image from '@material-ui/icons/Image'

export const orgRoutes = [
  {
    path: '/profile',
    name: 'Profile',
    icon: RecentActorsIcon,
    component: Scenes.Profile,
    layout: '/admin'
  },
  {
    path: '/gallery',
    name: 'Gallery',
    icon: Image,
    component: Scenes.Gallery,
    layout: '/admin'
  },
  {
    path: '/events/:id/media',
    name: 'Event',
    component: Scenes.EventImages,
    layout: '/admin',
    invisible: true
  },
  {
    path: '/events/:id',
    name: 'Event',
    component: Scenes.Event,
    layout: '/admin',
    invisible: true
  },
  {
    path: '/events',
    name: 'Events',
    icon: ConfirmationNumber,
    component: Scenes.Events,
    layout: '/admin'
  },
  {
    path: '/new-event',
    name: 'New Event',
    icon: ConfirmationNumber,
    component: Scenes.NewEvent,
    layout: '/admin',
    invisible: true
  }
]

export const rcaRoutes = [
  {
    path: '/overview',
    name: 'Overview',
    icon: DashboardIcon,
    component: Scenes.Dashboard,
    layout: '/admin'
  },
  {
    path: '/organizations/:id',
    name: 'Organizations',
    icon: RecentActorsIcon,
    component: Scenes.Organization,
    layout: '/admin',
    invisible: true
  },
  {
    path: '/organizations',
    name: 'Organizations',
    icon: RecentActorsIcon,
    component: Scenes.Organizations,
    layout: '/admin'
  },
  {
    path: '/applications/:id',
    name: 'Application',
    invisible: true,
    component: Scenes.Application,
    layout: '/admin'
  },
  {
    path: '/applications',
    name: 'Applications',
    icon: Assignment,
    component: Scenes.Applications,
    layout: '/admin'
  },
  {
    path: '/episodes/:id',
    name: 'Episode',
    icon: VideoLibraryIcon,
    component: Scenes.Episode,
    layout: '/admin',
    invisible: true
  },
  {
    path: '/episodes',
    name: 'Episodes',
    icon: VideoLibraryIcon,
    component: Scenes.Episodes,
    layout: '/admin'
  },
  {
    path: '/articles/:id',
    name: 'Articles',
    icon: ImportContactsIcon,
    component: Scenes.Article,
    layout: '/admin',
    invisible: true
  },
  {
    path: '/articles',
    name: 'Articles',
    icon: ImportContactsIcon,
    component: Scenes.Articles,
    layout: '/admin'
  }
]

import { gql } from '@apollo/client'

export default gql`
  query GET_ORGANIZATION($id: Int!) {
    organization: organizations_by_pk(id: $id) {
      about
      boxOfficeHours: box_office_hours
      createdAt: created_at
      email
      facebook
      id
      instagram
      name
      onboardingComplete: onboarding_complete
      phone
      slug
      status
      tagline
      twitter
      youtube
      updatedAt: updated_at
      website
    }
  }
`

import config from 'config'
import firebase from 'firebase/app'
import 'firebase/auth'
import request from 'superagent'

const { rcaApi } = config

export const getMe = async ({ token }) => {
  try {
    const { body } = await request
      .get(`${rcaApi}/me/dashboard`)
      .set('Authorization', token)

    return body
  } catch (e) {
    const { message } = e

    if (message) {
      window.alert(message)
    }

    return false
  }
}

export const updateRole = async ({ token, uid, role, remove }) => {
  try {
    const { body } = await request
      .put(`${rcaApi}/update-role`)
      .set('Authorization', token)
      .send({
        uid,
        role,
        remove
      })

    return body
  } catch (e) {
    const { message } = e

    if (message) {
      window.alert(message)
    }

    return false
  }
}

export const logoutUser = () => {
  return firebase.auth().signOut()
}

import React, { useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Onboarding from './Onboarding'
import { useStoreon } from 'storeon/react'
import { useLazyQuery } from '@apollo/client'
import { GET_ORGANIZATION, UPDATE_ORGANIZATION } from 'gql'
import GqlDetails from 'components/GqlDetails'

export default function Profile () {
  const { user, organizations } = useStoreon('user', 'organizations')
  const [getData, { data, loading }] = useLazyQuery(GET_ORGANIZATION)

  useEffect(() => {
    (async () => {
      if (user) {
        const token = await user.getIdToken()

        getData({
          variables: { id: organizations[0] },
          context: {
            headers: {
              authorization: `Bearer ${token}`,
              'X-Hasura-Role': 'organization'
            }
          }
        })
      }
    })()
  }, [getData, user, organizations])

  if (loading || !data) {
    return null
  }

  const renderBody = () => {
    const { onboardingComplete } = data.organization

    if (onboardingComplete) {
      return (
        <GqlDetails
          config={{
            noFetch: true,
            initialData: data.organization,
            gqlPut: UPDATE_ORGANIZATION,
            dataName: 'organization',
            formRows: [
              [
                { label: 'Name', id: 'name', adminOnly: true, type: 'input' },
                { label: 'Slug', id: 'slug', adminOnly: true, type: 'input' },
                { label: 'Tagline', id: 'tagline', type: 'input' }
              ],
              [
                { label: 'About', id: 'about', type: 'input', multiline: true }
              ],
              [
                { label: 'Website', id: 'website', type: 'input' },
                { label: 'Public Email', id: 'email', type: 'input' },
                { label: 'Public Phone Number', id: 'phone', type: 'input' }
              ],
              [
                { label: 'Facebook', id: 'facebook', type: 'input' },
                { label: 'Twitter', id: 'twitter', type: 'input' },
                { label: 'YouTube', id: 'youtube', type: 'input' },
                { label: 'Instagram', id: 'instagram', type: 'input' }
              ],
              [
                { label: 'Box Office Hours', id: 'boxOfficeHours', type: 'input', multiline: true }
              ]
            ]
          }}
        />
      )
    } else {
      return <Onboarding />
    }
  }

  return (
    <GridContainer justify='center'>
      <GridItem xs={12} sm={8}>
        {renderBody()}
      </GridItem>
    </GridContainer>
  )
}

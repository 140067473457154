import React, { useEffect, useState } from 'react'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CardBody from 'components/Card/CardBody.js'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useStoreon } from 'storeon/react'
import { useParams } from 'react-router-dom'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Select from 'components/Select'
import Datetime from 'react-datetime'
import styled from 'styled-components'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const GqlDetails = props => {
  const { config } = props
  const { gqlGet, gqlPut, formRows, dataName, initialData = {}, noFetch } = config
  const { user, isAdmin, dispatch } = useStoreon('user', 'isAdmin')
  const [getData, { data }] = useLazyQuery(gqlGet)
  const [updateData] = useMutation(gqlPut)
  const [formState, setFormState] = useState(initialData)
  const { id } = useParams()

  useEffect(() => {
    if (data) {
      const formData = data[dataName]
      setFormState(formData)
    }
  }, [data, dataName])

  useEffect(() => {
    (async () => {
      if (user && !noFetch) {
        const token = await user.getIdToken()

        getData({
          variables: { id },
          context: {
            headers: {
              authorization: `Bearer ${token}`,
              'X-Hasura-Role': isAdmin ? 'rca' : 'organization'
            }
          }
        })
      }
    })()
  }, [getData, isAdmin, user, id, noFetch])

  const handleChange = (key, value) => {
    setFormState(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const renderItem = (i, count, idx) => {
    const { label, id, adminOnly, multiline, type, options } = i

    if (type === 'select') {
      return (
        <GridItem xs={12} sm={12} md={12 / count} key={idx}>
          <Select
            label={label}
            value={formState[id] === undefined ? '' : formState[id]}
            onChange={e => handleChange(id, e.target.value)}
            options={options}
          />
        </GridItem>
      )
    }

    if (type === 'date') {
      return (
        <GridItem xs={12} sm={12} md={12 / count} key={idx}>
          <Column>
            <label>{label}</label>
            <Datetime
              timeFormat={false}
              inputProps={{ placeholder: label, label }}
              value={formState[id] || new Date()}
              onChange={value => handleChange(id, value)}
            />
          </Column>
        </GridItem>
      )
    }

    return (
      <GridItem xs={12} sm={12} md={12 / count} key={idx}>
        <CustomInput
          labelText={label}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            value: formState[id] || '',
            onChange: e => handleChange(id, e.target.value),
            readOnly: adminOnly && !isAdmin,
            multiline
          }}
        />
      </GridItem>
    )
  }

  const renderRow = (r, idx) => {
    return (
      <GridContainer key={idx} justify='center'>
        {r.map((i, iKey) => renderItem(i, r.length, iKey))}
      </GridContainer>
    )
  }

  const getDiff = () => {
    const changedVars = {}
    let unchanged = true

    Object.keys(formState).forEach(k => {
      if (formState[k] !== (initialData ? initialData[k] : data[dataName][k])) {
        unchanged = false
        changedVars[k] = formState[k]
      }
    })

    return unchanged ? false : changedVars
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const changedVars = getDiff()

    if (changedVars) {
      const token = await user.getIdToken()

      try {
        await updateData(({
          variables: formState,
          context: {
            headers: {
              authorization: `Bearer ${token}`,
              'X-Hasura-Role': isAdmin ? 'rca' : 'organization'
            }
          }
        }))

        dispatch('setNotification', { message: 'Successfully updated.' })
      } catch (e) {
        dispatch('setNotification', {
          message: 'Successfully updated.',
          color: 'danger'
        })
      }
    }
  }

  return (
    <CardBody>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {formRows.map(renderRow)}
        </GridContainer>
        <Button color='rose' size='lg' block type='submit' disabled={!getDiff()}>
          Update
        </Button>
      </form>
    </CardBody>
  )
}

export default GqlDetails

import { gql } from '@apollo/client'

export default gql`
  query GET_EPISODES($offset: Int) {
    metaData: episodes_aggregate {
      aggregate {
        count
      }
    }
    
    data: episodes (limit: 10, offset: $offset,  order_by: { id: desc }) {
      name
      id
      url
      slug
      createdAt: created_at
    }
  }
`

import { gql } from '@apollo/client'

export default gql`
  mutation ADD_TAG_TO_EPISODE($episodeId: Int!, $tagId:Int!) {
    insertEpisodeTag: insert_episode_x_tags(objects: [{episode_id: $episodeId, tag_id: $tagId}]) {
      returning {
        id
        tag {
          name
        }
      }
    }
  }
`

import styled from 'styled-components'

export const GalleryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > button {
    align-self: flex-end;
    width: 300px;
    margin-top: 16px;
  }
`

export const GalleryItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const UploadGalleryItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ImageCard = styled.div`
  height: 260px;
  width: 300px;
  overflow: hidden;
  margin-right: 16px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  * {
    color: white;
  }

  > div {
    &:first-child {
      height: 200px;
      width: 100%;
      background-image: url(${({ image }) => image});
      background-size: cover;
      background-position: center;
      border-radius: 10px;
    }

    &:last-child {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > * {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        margin-top: 8px;
        width: 90px;
        padding: 8px;
        border-radius: 4px;
        justify-content: center;
      }
    }
  }
`

export const ImagePreview = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;

  > div {
    &:first-child {
      height: 100%;
      width: 100%;
      background-image: url(${({ image }) => image});
      background-size: cover;
    }

    &:last-child {
      position: absolute;
      top: 8px;
      right: 8px;

      > svg {
        height: 24px;
        width: 24px;
        color: white;
      }
    }
  }
`

export const DropzoneWrapper = styled.div`
  height: 100px;
  width: 100%;
  max-width: 100%;
  border: 1px dashed #6D6A6A;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 16px 0px;
  cursor: pointer;
`

import { gql } from '@apollo/client'

export default gql`
  query GET_EVENTS($offset: Int) {
    metaData: events_aggregate {
      aggregate {
        count
      }
    }
    
    data: events (limit: 10, offset: $offset) {
      name
      startDate: start_date
      endDate: end_date
      id
    }
  }
`

import Applications from './Applications'
import Application from './Applications/Application'
import Articles from './Articles'
import Article from './Articles/Article'
import Episodes from './Episodes'
import Episode from './Episodes/Episode'
import Organizations from './Organizations'
import Organization from './Organizations/Organization'
import Dashboard from './Dashboard'
import Profile from './Profile'
import Gallery from './Gallery'
import Events from './Events'
import NewEvent from './Events/NewEvent'
import Event from './Events/Event'
import EventImages from './Events/EventImages'

export default {
  Application,
  Applications,
  Articles,
  Article,
  Episode,
  Episodes,
  Organizations,
  Organization,
  Dashboard,
  Profile,
  Gallery,
  Events,
  NewEvent,
  Event,
  EventImages
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js'

const useStyles = makeStyles(styles)

const CustomSelect = props => {
  const { value, onChange, options, label, multiple } = props
  const classes = useStyles()

  const renderMenuItem = (item, idx) => {
    const { label, value } = item

    return (
      <MenuItem
        key={idx}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelectedMultiple
        }}
        value={value}
      >
        {label}
      </MenuItem>
    )
  }

  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl}
    >
      <InputLabel
        htmlFor='multiple-select'
        className={classes.selectLabel}
      >
        {label}
      </InputLabel>
      <Select
        multiple={multiple}
        value={value}
        onChange={onChange}
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
      >
        {options.map(renderMenuItem)}
      </Select>
    </FormControl>
  )
}

export default CustomSelect

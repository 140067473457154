import { gql } from '@apollo/client'

export default gql`
  mutation ADD_EVENT_IMAGE(
    $images: [event_images_insert_input!]!
  ) {
    insert_event_images(objects: $images) {
      returning {
        id
      }
    }
  }
`

import React, { useEffect, useState, useCallback } from 'react'
import { GalleryWrapper, ImagePreview, UploadGalleryItems, GalleryItems, DropzoneWrapper, ImageCard } from './styled'
import { useDropzone } from 'react-dropzone'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_ORGANIZATION_GALLERY, DELETE_ORGANIZATION_IMAGE } from 'gql'
import { useStoreon } from 'storeon/react'
import { imgixUrl } from 'utils'
import { uploadOrganizationPhotos } from 'api'
import Button from 'components/CustomButtons/Button.js'

const Gallery = () => {
  const { organizations, user } = useStoreon('organizations', 'user')
  const [getData, { data }] = useLazyQuery(GET_ORGANIZATION_GALLERY)
  const [deleteImage] = useMutation(DELETE_ORGANIZATION_IMAGE)
  const [images, setImages] = useState([])
  const [photos, setPhotos] = useState([])
  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(acceptedFiles => {
    setPhotos(prev => [...prev, ...acceptedFiles.map(af => ({
      file: af,
      imgUrl: URL.createObjectURL(af)
    }))])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*'
  })

  const fetchGallery = useCallback(async () => {
    if (user && organizations) {
      const token = await user.getIdToken()
      const [id] = organizations

      getData({
        variables: { id },
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            'X-Hasura-Role': 'organization'
          }
        }
      })
    }
  }, [getData, organizations, user])

  useEffect(() => {
    fetchGallery()
  }, [getData, user, organizations, fetchGallery])

  useEffect(() => {
    if (data?.data) {
      setImages(data.data.map(i => ({
        ...i,
        imgUrl: imgixUrl(i.path, { auto: 'compress' })
      })))
    }
  }, [data])

  const onDelete = async id => {
    const token = await user.getIdToken()

    await deleteImage(({
      variables: { id },
      context: {
        headers: {
          authorization: `Bearer ${token}`,
          'X-Hasura-Role': 'organization'
        }
      }
    }))

    await fetchGallery()
  }

  const renderImage = (item, idx) => {
    return (
      <ImageCard key={idx} image={item.imgUrl}>
        <div />
        <div>
          <Button color='danger' onClick={() => onDelete(item.id)}>
            Delete
          </Button>
          <Button color='info'>
            <a target='_blank' rel='noopener noreferrer' href={item.imgUrl}>
              View
            </a>
          </Button>
        </div>
      </ImageCard>
    )
  }

  const onRemove = idxToRemove => {
    setPhotos(prev => prev.filter((item, idx) => idx !== idxToRemove))
  }

  const renderPhoto = (item, idx) => {
    return (
      <ImagePreview key={idx} image={item.imgUrl} onClick={() => onRemove(idx)}>
        <div />
        <div>
          {/* <Icon name='close' /> */}
        </div>
      </ImagePreview>
    )
  }

  const onUpload = async () => {
    const token = await user.getIdToken()
    const [id] = organizations

    setLoading(true)

    await uploadOrganizationPhotos({
      files: photos,
      token,
      organizationId: id
    })

    setLoading(false)

    setPhotos([])
    await fetchGallery()
  }

  return (
    <GalleryWrapper>
      <h1>Gallery Images</h1>
      <GalleryItems>
        {images.map(renderImage)}
      </GalleryItems>
      <h2>Add more photos</h2>
      <UploadGalleryItems>
        {photos.map(renderPhoto)}
      </UploadGalleryItems>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <DropzoneWrapper>
          {
            isDragActive
              ? <p>Drop the files here ...</p>
              : <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </DropzoneWrapper>
      </div>
      <Button disabled={photos.length === 0} color='rose' loading={loading} onClick={onUpload}>Upload</Button>
    </GalleryWrapper>
  )
}

export default Gallery

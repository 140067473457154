import styled from 'styled-components'

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    &:first-child {
      margin-bottom: 24px;
    }
  }
`

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'node-fetch'
import config from 'config'

const { gqlEndpoint } = config

const authLink = setContext(async (_, { headers }) => {
  const newHeaders = {
    ...headers
  }

  return { headers: newHeaders }
})

const httpLink = createHttpLink({
  uri: gqlEndpoint,
  fetch
})

const cache = typeof window !== 'undefined' && window.__INITIAL_STATE__
  ? new InMemoryCache().restore(window.__INITIAL_STATE__)
  : new InMemoryCache()

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
})

export default client

import queryString from 'query-string'

export const getQuery = history => {
  const { search } = history.location
  return queryString.parse(search)
}

export const updateQuery = (history, key, value, options = {}) => {
  const { search, pathname } = history.location
  const searchQuery = queryString.parse(search)
  const { remove, onDone, pagination } = options

  const newQuery = {
    ...searchQuery,
    [key]: value
  }

  if (remove || value === false) {
    delete newQuery[key]
  }

  if (!pagination) {
    delete newQuery.page
  }

  history.replace(`${pathname}?${queryString.stringify(newQuery)}`)

  if (onDone) {
    onDone()
  }

  window.scrollTo(0, 0)
}

export const updateQueryMulti = (history, values, options = {}) => {
  const { search, pathname } = history.location
  const searchQuery = queryString.parse(search)
  const { remove, onDone, pagination } = options

  let newQuery = searchQuery

  values.forEach(item => {
    const [key, value] = item

    newQuery = {
      ...newQuery,
      [key]: value
    }

    if (remove || value === false) {
      delete newQuery[key]
    }
  })

  if (!pagination) {
    delete newQuery.page
  }

  history.push(`${pathname}?${queryString.stringify(newQuery)}`)

  if (onDone) {
    onDone()
  }

  window.scrollTo(0, 0)
}
